.player-wrapper {
  width: 60vw;
  height: 28.5vw;
  position: relative;
  padding-left: 20px;
}
.react-player {
  margin-bottom: auto;
}

@media (max-width: 1000px) {
  .player-wrapper {
    width: 100vw;
    height: 50vw;
    padding-left: 0;
  }
}
