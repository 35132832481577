.Navbar {
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
  width: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  display: flex;
  position: fixed;
  transition: 0.25s;
  z-index: 5;
  height: 60px;
}

.Navbar img {
  width: 44px;
  transition: 0.2s;
  margin-top: 4px;
  padding: 7px 50px 7px 50px;
}

.Navbar img:hover {
  scale: 1.1;
}

.active img {
  scale: 1.1;
}

.scrolled img {
  width: 34px;
}

.scrolled {
  background-color: #2e2e2e;
  padding-top: 0;
  height: 50px;
}

@media (max-width: 1300px) {
  .Navbar {
    display: none;
  }
}
