.headerBackdrop {
  background: url("../../Assets/Home4.jpg") center;
  height: 70vh;
  background-size: cover;
}

.contact {
  margin: 0;
  width: 100%;
  text-align: center;
}

.contactText {
  font-size: 18px;
  padding: 0 20px;
}

.contactText a {
  color: #8cbcff;
}

.calendarWrapper {
  padding: 30px;
}

.calendar {
  background: white;
  border-radius: 6px;
  padding: 10px;
  color: black;
}
