.Toolbar {
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: space-between;
  width: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  display: flex;
  position: fixed;
  transition: 0.4s;
  z-index: 11;
  height: 68px;
}

.Toolbar img {
  width: 40px;
  margin: 14px;
  transition: 0.4s;
}

.scrolled {
  background-color: #2e2e2e;
  padding-top: 0;
}

.clicked {
  z-index: 11;
  position: fixed;
  margin-top: 68px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 15px 0 15px;
  transition: 0.4s;
}

.clickedScrolled {
  z-index: 11; /*must be greater than 10 since some of components on the photos page have a z-index of 10*/
  position: fixed;
  margin-top: 68px;
  background-color: #2e2e2e;
  padding: 0 15px 0 15px;
  transition: 0.4s;
}

.navLink {
  height: 42px;
}

@media (min-width: 1301px) {
  .Toolbar {
    display: none;
  }
}
