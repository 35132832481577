.div {
  position: fixed;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 0px;
  top: 0;
  z-index: 6;
  width: 207px;
  transition: 0.25s;
}

.Link {
  color: white;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
}

.Link:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
