.NavItem {
  margin: 0;
  display: block;
  width: 100%;
  text-align: center;
  transition: 0.4s;
}

.NavItem a {
  font-family: "Syncopate";
  font-weight: 600;
  display: flex;
  color: white;
  height: 60px;
  text-decoration: none;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: 0.2s;
  margin: 0 8px 0 8px;
}

.NavItem a:hover,
.NavItem a:active,
.NavItem a.active {
  /* color: #80b08a; */
  /* font-size: 14.5px; */
  /* padding-left: 10px; */
  .linkWrapper {
    border-bottom: 4px solid white;
  }
  /* margin: 0 20px 0 20px; */
}

.scrolled a:hover,
.scrolled a:active,
.scrolled a.active {
  height: 50px;
}

@media (max-width: 500px) {
  .NavItem a.active {
    .linkWrapper {
      border-bottom: none;
    }
  }
}

.linkWrapper {
  display: flex;
  width: 207px;
  height: 60px;
  transition: 0.2s;
  align-items: center;
}

.linkWrapper p {
  text-align: center;
  width: 100%;
}

.scrolled {
  .linkWrapper {
    height: 50px;
  }
}
