.location {
  padding-bottom: 50px;
}

.location li {
  margin-left: 20px;
  line-height: 25px;
}

.mapsLink {
  width: 100%;
  display: flex;
  justify-content: center;
}
.mapsLink a {
  color: #8cbcff;
  text-align: center;
}

.listHeader {
  margin-left: 40px;
}

.extraMargin {
  margin-top: 80px;
}

.address {
  text-align: center;
  margin-bottom: 5px;
}

.headerBackdrop {
  background: url("../../Assets/VenueUpdated4.jpg") center left;
  height: 70vh;
  background-size: cover;
}

@media (max-width: 1000px) {
  .headerBackdrop {
    background: url("../../Assets/Location0small.jpg") center;
    height: 70vh;
    background-size: cover;
  }
  .extraMargin {
    margin-top: 100px;
  }
}
