.home {
  width: 100%;
}

.homeImage {
  width: 100%;
  margin: 15px 0;
}

.headerBackdrop {
  background: url("../../Assets/foggyAmphitheater.jpg") left;
  height: 90vh;
  background-size: cover;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.header img {
  width: 220px;
  /* background: rgba(255, 255, 255, 0.3); */
  background: rgba(0, 0, 0, 0.3);
  border-radius: 120px;
  /* box-shadow: 0 0 10px white; */
}

.header h1 {
  color: white;
  font-family: "Syncopate", sans-serif;
  font-weight: 100;
  font-size: 35px;
  text-shadow: 0 0 10px black, 0 0 5px black, 0 0 20px black, 0 0 15px black,
    0 0 12px black;
  padding: 0 15px;
  margin: 20px 0;
}

.homeBlurb {
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;
}

.homeBlurbText {
  width: 40vw;
  padding: 0 20px 0 20px;
  line-height: 25px;
}

.labeledVenue {
  width: 60vw; /* Sets the width of the image to 40% of the viewport width */
  height: auto; /* Maintains the aspect ratio of the image */
  object-fit: contain; /* Ensures the image is scaled to fit within its box, retaining its aspect ratio */
  margin-left: 20px;
}

.homeSubheader {
  padding: 0 20px;
  width: calc(100% - 40px);
  text-align: center;
  margin: 50px 0 50px 0;
  font-size: 30px;
  font-weight: 100;
}

@media (max-width: 1000px) {
  .homeBlurb {
    flex-direction: column;
  }
  .homeBlurbText {
    width: calc(100% - 40px);
    padding: 0;
    padding: 0 20px 0 20px;
  }
  .labeledVenue {
    width: calc(100% - 40px);
    padding: 0;
    padding: 0 20px 0 20px;
    margin-left: 0;
  }
  .headerBackdrop {
    background: url("../../Assets/foggyAmphitheater.jpg") center;
    height: 90vh;
    background-size: cover;
  }
}
