body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("./Assets/Backdrop.webp");
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.45);
  color: #ebebeb;
  /* overflow-x: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Syncopate";
  src: local("Syncopate-Regular"),
    url("./Fonts/Syncopate-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Metropolis";
  src: local("Metropolis-Medium"),
    url("./Fonts/Metropolis-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Metropolis-light";
  src: local("Metropolis-Light"),
    url("./Fonts/Metropolis-Light.otf") format("opentype");
}
