.headerBackdrop {
  background: url("../../Assets/Wedding1.jpg") center;
  height: 70vh;
  background-size: cover;
}

.slideshow {
  width: 80%;
  margin: auto;
  padding-bottom: 30px;
}

.slideshow img {
  width: 100%;
}

.slideshowLabel {
  text-align: center;
}

.imgWrapper {
  height: 80vh;
  display: flex;
  justify-content: center;
}

.imgWrapper img {
  object-fit: contain;
  width: 98%;
}

@media (max-width: 1000px) {
  .headerBackdrop {
    background: url("../../Assets/Wedding1.jpg") center;
    height: 70vh;
    background-size: cover;
  }

  .imgWrapper {
    height: 45vh;
  }
}
