.headerBackdrop {
  background: url("../../Assets/Venue36.jpg") left;
  height: 70vh;
  background-size: cover;
}

@media (max-width: 1000px) {
  .headerBackdrop {
    background: url("../../Assets/Venue36small.jpg") left;
    height: 70vh;
    background-size: cover;
  }
}

.vendorTable {
  width: calc(100% - 100px);
  margin: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.link {
  color: #8cbcff;
}

.vendorTable a {
  color: #8cbcff;
}

.vendorCell {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.vendorCell img {
  width: 200px;
  margin: auto;
  margin-bottom: 10px;
  /* mix-blend-mode: multiply; */
}

.vendorDescription {
  width: calc(100% - 100px);
  margin: 10px auto;
}

@media (max-width: 1000px) {
  .vendorCell img {
    width: 100px;
  }
  .vendorTable {
    width: calc(100% - 50px);
  }
  .vendorDescription {
    width: calc(100% - 50px);
  }
}
