.headerBackdrop {
  background: url("../../Assets/Barn1.jpg") center;
  height: 70vh;
  background-size: cover;
}

.packagesDescription {
  text-align: center;
  font-size: large;
}

.packagePDFwrapper {
  width: 95vw;
  overflow: auto;
  margin: auto;
}

.packagePDF {
  width: 1200px;
  margin: auto;
  overflow: auto;
}

@media (max-width: 1000px) {
  .headerBackdrop {
    background: url("../../Assets/Barn1.jpg") center;
    height: 70vh;
    background-size: cover;
  }

  .packagePDF {
    width: 400px;
    height: 400px;
    margin: auto;
    overflow: auto;
  }
}
