.map {
  width: calc(100% - 100px);
  margin: 0 auto;
}

.map-h2 {
  font-size: 1rem;
  padding: 20px;
  text-align: center;
}

.google-map {
  width: 100%;
  height: 60vh;
}

@media screen and (min-width: 799px) {
  /* .google-map {
    height: 80vh;
  } */

  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    width: 15vw;
  }

  .pin-icon {
    font-size: 10vw;
  }
}
