.headerBackdrop {
  background: url("../../Assets/PeacockPhoto.jpg") left;
  height: 90vh;
  background-size: cover;
}

.header h1 {
  color: white;
  font-family: "Syncopate", sans-serif;
  font-size: 30px;
  text-shadow: 0 0 10px black;
  padding: 0 15px;
  margin: 20px 0;
}

.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content a {
  color: #57d2ff;
}

.faqSection,
.rulesSection {
  width: 80%;
  margin-bottom: 20px;
  color: white;
  padding: 20px;
}

.faqSection h2,
.rulesSection h2 {
  text-align: center;
  margin-bottom: 15px;
}

.faqItem {
  margin-bottom: 10px;
}

.faqItem h3 {
  font-size: 20px;
}

.faqItem p {
  font-size: 16px;
}

.rulesSection ul {
  list-style-type: none;
  padding: 0;
}

.rulesSection li {
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 1.6;
}

@media (max-width: 1000px) {
  .headerBackdrop {
    background: url("../../Assets/PeacockPhoto.jpg") center;
    background-size: cover;
  }

  .content {
    padding: 10px;
  }

  .faqSection,
  .rulesSection {
    width: 90%;
  }
}
